<template>
  <div class="lnb">
    <div class="lnbLogo">
      <router-link to="/">
        <img src="@/assets/images/logo_white.png" alt="logo" />
      </router-link>
    </div>

    <el-row class="tac mainMenu">
      <el-col :span="12">
        <el-menu default-active="1" class="el-menu-vertical-demo">
          <el-menu-item index="1">
            <router-link to="/admin/dashboard">
              <span :class="{ active: navbarState == 0 }">
                <img
                  src="@/assets/images/icon_svg/LNB_group.svg"
                  alt="icon"
                  class="normal"
                />
                HOME
              </span>
            </router-link>
          </el-menu-item>
          <el-menu-item index="2">
            <router-link to="/admin/users">
              <span :class="{ active: navbarState == 1 }">
                <img
                  src="@/assets/images/icon_svg/LNB_group.svg"
                  alt="icon"
                  class="normal"
                />
                Administration
              </span>
            </router-link>
          </el-menu-item>
          <!-- <el-menu-item index="3">
            <router-link to="/admin/posts">
              <span :class="{ active: navbarState == 2 }">
                <img
                  src="@/assets/images/icon_svg/LNB_register.svg"
                  alt="icon"
                  class="normal"
                />
                검색 관리
              </span>
            </router-link>
          </el-menu-item> -->

          <!-- <el-menu-item index="4">
            <router-link to="/admin/reviews">
              <span :class="{ active: navbarState == 3 }">
                <img
                  src="@/assets/images/icon_svg/LNB_dashboard.svg"
                  alt="icon"
                  class="normal"
                />
                리뷰 관리
              </span>
            </router-link>
          </el-menu-item> -->
          <el-menu-item index="5">
            <!-- <a @click="logout">
              <span>
                <img
                  src="@/assets/images/icn-logout.svg"
                  alt="icon"
                  class="normal"
                />
                로그아웃
              </span>
            </a> -->
            <button @click="logout" class="logout">
              <span>
                <img
                  src="@/assets/images/icn-logout.svg"
                  alt="icon"
                  class="normal"
                />
                Log Out
              </span>
            </button>
          </el-menu-item>
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      isActive: false,
      isDropped: false,
    };
  },
  computed: {
    ...mapState(["navbarState", "type"]),
  },
  methods: {
    logout() {
      let result = confirm("Are you sure you want to log out?");
      if (result == false) {
        return;
      }
      if (this.type == "GOOGLE") {
        window.google.accounts.id.disableAutoSelect();
      }
      this.$store.dispatch("LOGOUT");
    },
  },
};
</script>
